<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="KYC Detail" icon="fa-solid fa-address-card" />

    <div class="generalFullWidthBox border shadow-sm">
        <div v-if="isLoading === true" class="row">
            <div class="col-12">
                <fa icon="spinner" size="lg" class="me-2" />Loading...
            </div>
        </div>

        <div v-if="isLoading === false" class="row">
            <div class="col-12 fw-bold pb-3">
                <table width="100%">
                    <tr>
                        <td>
                            <Popper class="popperDark" arrow hover content="Back">
                                <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/backoffice/kyc' })" />
                            </Popper>
                            Personal Information 
                        </td>
                        <td width="80px" class="text-end">
                            <span @click="showLog = !showLog" class="text-grey isLink">
                                <fa icon="file-lines" size="lg" />
                            </span>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-12 pb-3 text-center">
                <div class="row">
                    <div class="col-lg-3 mb-2">
                        <span v-if="user.jAvatar">
                            <ImgAvatar key="1" isUser="true" :id="user.jAvatar.userId" displayName="" :isVerified="user.jIdentity.isVerified" :isActive="user.status" privilege="" align="center" width="120" height="120" />
                            <!-- <Avatar key="1" isUser="true" :avatar="user.jAvatar.imgBase64" displayName="" :isVerified="user.jIdentity.isVerified" :isActive="user.status" align="center" width="120" height="120"/> -->
                        </span>
                    </div>

                    <div class="col-lg-9 text-start">
                        <div class="mb-2">
                            {{user.status}}
                            <span class="float-end">
                                <span v-if="viewType === 'KYC'">{{user.kycStatus}}</span>
                                <span v-if="viewType === 'PROFILE'">{{user.jPendingProfile.status}}</span>
                                <span v-if="viewType === 'PASSPORT'">{{user.jPendingPassport.status}}</span>
                                
                                <span v-if="user.kycRemark !== ''" class="ms-2">
                                    <Popper class="popperDark" arrow hover :content="'Rejected reason: ' + user.kycRemark">
                                    <fa icon="clipboard" class="isLink" />
                                    </Popper>
                                </span>
                                <span v-if="user.kycNote !== ''" class="ms-2">
                                    <Popper class="popperDark" arrow hover :content="'Private note: ' + user.kycNote">
                                    <fa icon="note-sticky" class="isLink" />
                                    </Popper>
                                </span>
                                <div class="text-end text-grey small">{{viewType}}</div>
                            </span>
                        </div>
                        <div class="mb-2">
                            <span v-if="user.fullName === ''" class="fw-bold"></span>
                            <span v-else class="fw-bold">{{user.fullName}}</span>
                            <span v-if="user.displayName!== ''"> ({{user.displayName}})</span>
                            <div class="small text-grey">{{user.userId}}</div>
                        </div>

                        <div class="mb-2">
                            <div><span class="badge bg-secondary text-uppercase">{{user.plan}}</span></div>
                            <div>joined <span class="fst-italic">{{func.convDateTimeFormat(user.dateCreate, 'text')}}</span> <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> last login {{ func.getDateTimeDiff(func.convDateTimeFormat(user.dateLastLogin), new Date()) }}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div> 

        <div v-if="isLoading === false && showLog" class="row px-3">
            <div class="generalFullWidthContentBox border">
                <div class="mb-2 text-grey fw-bold">History Logs</div>
                <div class="text-grey">
                    <table width="100%">
                        <tr v-for="log in arrLog" :key="log">
                            <td>
                                <div class="row mb-2">
                                    <div class="col-lg-3">{{func.convDateTimeFormat(log.date, 'datetime')}}</div>
                                    <div class="col-lg-9">{{log.description}}</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div v-if="isLoading === false" class="row px-3 mb-0">
            <div class="generalFullWidthContentBox border">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Full legal name</div> 
                        <div>{{ user.fullName }}</div>
                    </div>

                    <div class="col-12 col-lg-6">
                        <div class="lblTiny">Gender</div> 
                        <div v-if="user.gender === 'F'">Female</div>
                        <div v-if="user.gender === 'M'">Male</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Address</div> 
                        <div>{{ user.jAddress.address }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Country</div> 
                        <div>{{ user.jAddress.countryName }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Date of birth</div> 
                        <div>{{ user.dob }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">NRIC (National Registration Identity)</div> 
                        <div>{{ user.jIdentity.nric }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Passport</div> 
                        <div>{{ user.jIdentity.passport }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Passport expiry</div> 
                        <div>{{ user.jIdentity.passportExpiry }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Passport country</div> 
                        <div>{{ user.jIdentity.passportIssueCountryName }}</div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div v-if="isLoading === false" class="generalFullWidthContentBox border shadow-sm mt-0">
        <div class="row">
            <div class="col-12 fw-bold pb-2">NRIC / Passport</div>

            <div v-if="user.jKYC && user.jKYC.length === 0" class="mb-4">Image not found</div>    
            <div v-for="img in user.jKYC" :key="img" class="col-12 mb-4 mt-2">
                <div>
                    <span v-if="img.type === 'frontMyKad'">NRIC Front</span>
                    <span v-if="img.type === 'backMyKad'">NRIC Back</span>
                    <span v-if="img.type === 'passport'">Passport</span>
                    <fa v-if="img.mimeType !== 'application/pdf'" icon="magnifying-glass-plus" size="lg" class="ms-1 isLink" @click="imgName = img.type; imgSrc = img.imgBase64" data-bs-toggle="modal" data-bs-target="#mdlShowImg"/>
                </div>
                <div v-if="img.mimeType === 'application/pdf'">
                    <span class="isLink" @click="downloadKyc(img.dlUrl)"><fa icon="file-pdf" class="me-2" />Download</span>
                </div>
                <div v-else>
                    <!-- <div class="text-danger small">{{img}}</div> -->
                    <div v-if="img.type === 'frontMyKad'">
                        <ImgBOKyc :id="img.id" :user="user.userId" :width="img.width" :height="img.height" label="kyc" />
                        <!-- <img :src="'data:' + img.mimeType + ';base64, ' + img.imgBase64" class="img-fluid" /> -->
                    </div>
                    <div v-if="img.type === 'backMyKad'">
                        <ImgBOKyc :id="img.id" :user="user.userId" :width="img.width" :height="img.height" label="kyc" />
                        <!-- <img :src="'data:' + img.mimeType + ';base64, ' + img.imgBase64" class="img-fluid" /> -->
                    </div>
                    <div v-if="img.type === 'passport'">
                        <ImgBOKyc :id="img.id" :user="user.userId" :width="img.width" :height="img.height" label="kyc" />
                        <!-- <img :src="'data:' + img.mimeType + ';base64, ' + img.imgBase64" class="img-fluid" /> -->
                    </div>
                </div>
                <div><hr /></div>
            </div>

            <div v-if="user.jIdentity && viewType === 'KYC'" class="col-12 text-center">
                <button class="btn btn-outline-secondary me-2" @click="$router.push({ path: '/backoffice/kyc' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>
                <button v-if="user.kycStatus === 'SUBMITTED'" data-bs-toggle="modal" data-bs-target="#mdlUpdStatus" @click=" kycStatus = 'PROCESS'" class="btn btn-secondary me-2">
                    <fa icon="user-gear" size="lg" class="me-2" />Process
                </button>
                <button v-if="user.kycStatus === 'PROCESS'" data-bs-toggle="modal" data-bs-target="#mdlUpdStatus" @click=" kycStatus = 'REJECTED'" class="btn btn-secondary me-2">
                    <fa icon="user-xmark" size="lg" class="me-2" />Reject
                </button>
                <button v-if="user.kycStatus === 'PROCESS'" data-bs-toggle="modal" data-bs-target="#mdlUpdStatus" @click=" kycStatus = 'VERIFIED'" class="btn btn-secondary">
                    <fa icon="user-shield" size="lg" class="me-2" />Verify
                </button>
            </div>

        </div>
    </div>

    <div v-if="isLoading === false && viewType === 'PROFILE'" class="generalFullWidthContentBox border shadow-sm mt-0">
        <div class="col-12 fw-bold pb-2">Request to Update Personal Information</div>
        <div class="row">
            <div class="col-12 mb-4">
                <div class="lblTiny">Date request</div> 
                <div>{{func.convDateTimeFormat(user.jPendingProfile.dateCreate, 'datetime')}}</div>
            </div>

            <div class="col-12 col-lg-6 mb-4">
                <div class="lblTiny">Full legal name</div> 
                <div>{{ user.jPendingProfile.fullName }}</div>
            </div>

            <div class="col-12 col-lg-6">
                <div class="lblTiny">Gender</div> 
                <div v-if="user.jPendingProfile.gender === 'F'">Female</div>
                <div v-if="user.jPendingProfile.gender === 'M'">Male</div>
            </div>

            <div class="col-12 col-lg-6 mb-4">
                <div class="lblTiny">NRIC (National Registration Identity)</div> 
                <div>{{ user.jPendingProfile.nric }}</div>
            </div>

            <div class="col-12 col-lg-6 mb-4">
                <div class="lblTiny">Address</div> 
                <div>{{ user.jPendingProfile.address }}</div>
            </div>

            <div class="col-12 col-lg-6 mb-4">
                <div class="lblTiny">Country</div> 
                <div>{{ user.jPendingProfile.countryName }}</div>
            </div>

            <div class="col-12"><hr /></div>

            <!-- <div class="col-12 text-danger small">{{ user.jPendingProfile }}</div> -->

            <div class="col-12 text-center">
                <button class="btn btn-outline-secondary me-2" @click="$router.push({ path: '/backoffice/kyc' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>

                <button v-if="user.jPendingProfile.status === 'SUBMITTED'" @click="updAction = 'REJECTED'" data-bs-toggle="modal" data-bs-target="#mdlConfirmUpdProfile" class="btn btn-secondary me-2">
                    <fa icon="user-xmark" size="lg" class="me-2" />Reject
                </button>
                <button v-if="user.jPendingProfile.status === 'SUBMITTED'" @click="updAction = 'APPROVED'" data-bs-toggle="modal" data-bs-target="#mdlConfirmUpdProfile" class="btn btn-secondary">
                    <fa icon="user-shield" size="lg" class="me-2" />Approve
                </button>
            </div>
        </div>

    </div>

    <div v-if="isLoading === false && viewType === 'PASSPORT'" class="generalFullWidthContentBox border shadow-sm mt-0">
        <div class="col-12 fw-bold pb-2">Request to Update Passport</div>
        <div class="row">
            <!-- <div class="col-12 text-danger small">{{ user.jPendingPassport }}</div> -->

            <div class="col-12 mb-4">
                <div class="lblTiny">Date request</div> 
                <div>{{func.convDateTimeFormat(user.jPendingPassport.dateCreate, 'datetime')}}</div>
            </div>

            <div class="col-12 col-lg-6 mb-4">
                <div class="lblTiny">Passport</div> 
                <div>{{ user.jPendingPassport.passport }}</div>
            </div>

            <div class="col-12 col-lg-6 mb-4">
                <div class="lblTiny">Passport expiry</div> 
                <div>{{ user.jPendingPassport.passportExpiry }}</div>
            </div>

            <div class="col-12 col-lg-6 mb-4">
                <div class="lblTiny">Passport country</div> 
                <div>{{ user.jPendingPassport.passportIssueCountryName }}</div>
            </div>

            <div class="col-12"><hr /></div>

            <div class="col-12 text-center">
                <button class="btn btn-outline-secondary me-2" @click="$router.push({ path: '/backoffice/kyc' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>

                <button v-if="user.jPendingPassport.status === 'SUBMITTED'" @click="updAction= 'REJECTED'" data-bs-toggle="modal" data-bs-target="#mdlConfirmUpdPassport" class="btn btn-secondary me-2">
                    <fa icon="user-xmark" size="lg" class="me-2" />Reject
                </button>
                <button v-if="user.jPendingPassport.status === 'SUBMITTED'" @click="updAction = 'APPROVED'" data-bs-toggle="modal" data-bs-target="#mdlConfirmUpdPassport" class="btn btn-secondary">
                    <fa icon="user-shield" size="lg" class="me-2" />Approve
                </button>
            </div>
        </div>
    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlConfirmUpdProfile"
        tabindex="-1"
        aria-labelledby="UpdProfile"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="updAction === 'REJECTED'" class="modal-title"> Reject</h5>
                    <h5 v-if="updAction === 'APPROVED'" class="modal-title"> Approve</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div v-if="updAction === 'REJECTED'" class="col-12">
                            <div class="mb-2">Confirm to reject personal information update?</div>
                            <div class="mb-2">Rejected reason below will send to respective user</div>
                            <div><textarea class="form-control mb-3" rows="5" v-model="profileRejectReasonPublic"></textarea></div>
                            <div class="mb-2">Private note</div>
                            <div><textarea class="form-control" rows="5" v-model="profileRejectReasonPrivate"></textarea></div>
                        </div>

                        <div v-if="updAction === 'APPROVED'" class="col-12">
                            Confirm to approve personal information update?
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="updAction === 'REJECTED' && profileRejectReasonPublic !== ''" @click="updProfileStatus('REJECTED')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                    <button v-if="updAction === 'APPROVED'" type="button" @click="updProfileStatus('APPROVED')" data-bs-dismiss="modal" class="btn btn-secondary">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlConfirmUpdPassport"
        tabindex="-1"
        aria-labelledby="UpdPassport"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="updAction === 'REJECTED'" class="modal-title"> Reject</h5>
                    <h5 v-if="updAction === 'APPROVED'" class="modal-title"> Approve</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div v-if="updAction === 'REJECTED'" class="col-12">
                            <div class="mb-2">Confirm to reject passport update?</div>
                            <div class="mb-2">Rejected reason below will send to respective user</div>
                            <div><textarea class="form-control mb-3" rows="5" v-model="passportRejectReasonPublic"></textarea></div>
                            <div class="mb-2">Private note</div>
                            <div><textarea class="form-control" rows="5" v-model="passportRejectReasonPrivate"></textarea></div>
                        </div>

                        <div v-if="updAction === 'APPROVED'" class="col-12">
                            Confirm to approve passport update?
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="updAction === 'REJECTED' && passportRejectReasonPublic !== ''" @click="updPassportStatus('REJECTED')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                    <button v-if="updAction === 'APPROVED'" type="button" @click="updPassportStatus('APPROVED')" data-bs-dismiss="modal" class="btn btn-secondary">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal : Show KYC Image --> 
    <div
        class="modal fade"
        id="mdlShowImg"
        tabindex="-1"
        aria-labelledby="ShowImg"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" v-if="imgName === 'frontMyKad'">NRIC front</h5>
                    <h5 class="modal-title" v-if="imgName === 'backMyKad'">NRIC back</h5>
                    <h5 class="modal-title" v-if="imgName === 'passport'">Passport</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div style="overflow: auto;">
                        <div><img :src="'data:image/png;base64, ' + imgSrc" class="img-fluid" /></div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlUpdStatus"
        tabindex="-1"
        aria-labelledby="UpdStatus"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="kycStatus === 'PROCESS'" class="modal-title"> Process</h5>
                    <h5 v-if="kycStatus === 'REJECTED'" class="modal-title"> Reject</h5>
                    <h5 v-if="kycStatus === 'VERIFIED'" class="modal-title"> Verify</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div v-if="kycStatus === 'PROCESS'" class="col-12">
                            Confirm to process this subscriber record within 24 hours.
                        </div>

                        <div v-if="kycStatus === 'REJECTED'" class="col-12">
                            <div class="mb-2">Rejected reason below will send to respective subscriber</div>
                            <div><textarea class="form-control mb-3" rows="5" v-model="rejectReasonPublic"></textarea></div>
                            <div class="mb-2">Private note</div>
                            <div><textarea class="form-control" rows="5" v-model="rejectReasonPrivate"></textarea></div>
                        </div>

                        <div v-if="kycStatus === 'VERIFIED'" class="col-12">
                            Confirm to verify subscriber as KYC subscriber.
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="kycStatus === 'PROCESS'" @click="confirmProcess" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                    <button v-if="kycStatus === 'REJECTED' && rejectReasonPublic !== ''" @click="confirmReject" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                    <button v-if="kycStatus === 'VERIFIED'" @click="confirmApprove" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ImgBOKyc from '@/components/ImgBOKyc.vue'
import Tag from '@/components/ProjTag.vue'

/* // Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
) */

export default {
    name: 'BOKycProfile',
    components: { Alert, TopNavigation, Popper, ImgAvatar, ImgBOKyc, /* Avatar, FilePond, */ Tag },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const isLoading = ref(true)  
        const showLog = ref(false)
        const user = ref({})
        const userId = ref(null)
        const viewType = ref(null) // KYC/PROFILE/PASSPORT
        const updAction = ref(null) // REJECTED/APPROVED

        const rejectReasonPublic = ref('')  // Disclose to user - remark field
        const rejectReasonPrivate = ref('') // For SU only, not disclose to user - note field
        const kycStatus = ref(null)

        const profileRejectReasonPublic = ref('')  // Disclose to user - remark field
        const profileRejectReasonPrivate = ref('') // For SU only, not disclose to user - note field

        const passportRejectReasonPublic = ref('')  // Disclose to user - remark field
        const passportRejectReasonPrivate = ref('') // For SU only, not disclose to user - note field

        const arrLog = ref([])

        const imgName = ref('')
        const imgSrc = ref('')


        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const updProfileStatus = (updStatus) => {
            const p = {
                userId: userId.value,
                type: 'PROFILE',
                remark: profileRejectReasonPublic.value,
                note: profileRejectReasonPrivate.value
            }
            axios.put('/user/su/user/pending/' + updStatus, p)
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {

                        getUser()

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record is updated to " + updStatus +"."
                        })

                    } else {
                        func.addLog('BO:userKycDetail', 'updProfileStatus', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('BO:userKycDetail', 'updProfileStatus - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const updPassportStatus = (updStatus) => {
            const p = {
                userId: userId.value,
                type: 'PASSPORT',
                remark: passportRejectReasonPublic.value,
                note: passportRejectReasonPrivate.value
            }

            axios.put('/user/su/user/pending/' + updStatus, p)
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {

                        getUser()

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record is updated to " + updStatus +"."
                        })

                    } else {
                        func.addLog('BO:userKycDetail', 'updPassportStatus', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('BO:userKycDetail', 'updPassportStatus - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
                
        }

        const downloadKyc = async (url) => {
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = 'kyc.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const confirmProcess = () => {
            const p = {
                userId: userId.value,
                action: 'PROCESS'
            }

            axios.put('/user/su/user/verify', p)
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {

                        getUser()

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record is updated to process."
                        })

                    } else {
                        func.addLog('BO:userKycDetail', 'confirmProcess', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('BO:userKycDetail', 'confirmProcess - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const confirmApprove = () => {
            const p = {
                userId: userId.value,
                action: 'APPROVED'
            }

            axios.put('/user/su/user/verify', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        getUser()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record updated to approved."
                        })

                    } else {
                        func.addLog('BO:userKycDetail', 'confirmApprove', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('BO:userKycDetail', 'confirmApprove - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const confirmReject = () => {
            const p = {
                userId: userId.value,
                action: 'REJECTED',
                remark: rejectReasonPublic.value,
                note: rejectReasonPrivate.value
            }

            axios.put('/user/su/user/verify', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {

                        getUser()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record updated to rejected."
                        })

                    } else {
                        func.addLog('BO:userKycDetail', 'confirmReject', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('BO:userKycDetail', 'confirmReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const getUser = async () => {
            try {
                const p = {
                    userId: userId.value,
                    showKYC: true
                }

                const res = await axios.post('/user/su/user', p)
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    // console.info('getUser', JSON.stringify(res.data))
                    user.value = res.data.data
                    arrLog.value = res.data.data.jKYCLog
                    
                } else {
                    func.addLog('BO:userKycDetail', 'getUser', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                }

            } catch (error) {
                func.addLog('BO:userKycDetail', 'getUser - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            }
        }

        
        onMounted(async () => {
            userId.value = route.params.userId
            viewType.value = route.params.type
            await getUser()
            isLoading.value = false

        })

        return {
            alert, closeAlert, router, func, store, showLog, isLoading, 
            userId, viewType, user, arrLog, imgName, imgSrc, rejectReasonPublic, rejectReasonPrivate,  kycStatus, 
            confirmProcess, confirmApprove, confirmReject, downloadKyc, updProfileStatus, updPassportStatus, updAction,
            profileRejectReasonPublic, profileRejectReasonPrivate, passportRejectReasonPublic, passportRejectReasonPrivate
        }
    },
    
}
</script>

<style>
.customLink {
    z-index: 10000; 
    padding-top: 70px; 
    opacity: 0.6;
    visibility: visible;
    background-color: white;
    width: 122px;
}

.editLink {
    font-size: 18px;
    padding: 8px; 
}

.editLink:hover {
    background-color: #DADADB;
    padding: 8px 10px;
    border-radius: 20px;
}

</style>